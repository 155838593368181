import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useTranslation} from "../lang/TranslationContext";

function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const year = String(date.getFullYear()).slice(-2); // Get last 2 digits of the year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
}

const Game = ({tg, openModal}) => {
    const { t } = useTranslation();

    const [gamesHistory, setGamesHistory] = useState([]);
    const [amount, setAmount] = useState(0.001);
    const [bet, setBet] = useState(1);
    const [result, setResult] = useState(-1)
    const [resultImg, setResultImg] = useState(1)
    const [resultText, setResultText] = useState(' ')
    const [balance, setBalance] = useState(0);
    const fetchGamesHistory = async () => {
        const initData = tg && tg.initData ? tg.initData : null;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}getGamesHistory.php`, 
              {
                headers: {
                  'Authorization': initData,
                  'Content-Type': 'application/json'
              },
            }
            )
            .then(response => {
                setGamesHistory(response.data.history);
                setBalance(Number(response.data.balance));
            }).catch(error => {
              console.log('Error fetching data:', error);
            });
    };

    const playGame = async () => {
        if(amount <= 0) {
            alert(t('Amount must be more then 0'))
            return true;
        }
        if(bet == -1) {
            alert(t('Please select Eagle or Tails'))
            return true;
        }
        try {
            setResult(-1)
        const initData = tg && tg.initData ? tg.initData : null;
                const response = await fetch(`${process.env.REACT_APP_API_URL}playGame.php`, {
                        method: 'POST',
                        headers: {
                                'Authorization': initData || '',
                                'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({'amount': amount}),
                });

                const result = await response.json();
                if (result.status === 'success') {
                    if(result.won == true) {
                        setResult(1)
                        setResultText('You won ' + Number(amount * 2).toFixed(4) + ' TON')
                        if(bet == 1) {
                            setResultImg(1)
                        } else {
                            setResultImg(2)
                        }
                    } else {
                        setResult(0)
                        setResultText(t('You lost, try again'))
                        if(bet == 1) {
                            setResultImg(2)
                        } else {
                            setResultImg(1)
                        }
                    }
                    fetchGamesHistory()
                } else {
                    alert(result.message)
                }
        } catch (error) {
            console.log(error)
        }
};
    
  
    useEffect(() => {
        fetchGamesHistory();
    }, [tg]);


    return (
        <>
  
            <main>
                <section class="game-section">
                <div class="container">
                    <div class="game-section__box">
                    <div class="main-top">
                        <h1 class="title">
                        {t("Coinflip")}
                        <svg width='24' height='24'>
                            <use href='img/sprite/sprite.svg?v=2#i'></use>
                        </svg>
                        </h1>
                    </div>
                    

                    <div class="main-info main-info--mode">
                        <div class="main-info__box">
                        <div class="main-info__inner">
                            <div class="main-info__icon">
                            <img width='47' height='47' src='img/index/ton2.png' alt='image'/>
                            </div>
                            <span class="main-info__title">{t("Balance")}:</span>
                            <span class="main-info__value">
                            <b>{Number(balance.toFixed(4))}</b> TON
                            </span>
                        </div>
                        <div >
                        <button
                            type="button"
                            style={{float:"left"}}
                            onClick={() => openModal("balanceGame")}
                            className={`main-btn `}
                            >
                            {t("Deposit")}</button>

                            <button
                            style={{float:"left"}}
                            type="button"
                            onClick={() => openModal("gameOut")}
                            className={`main-btn `}
                            >
                            {t("Withdraw")}</button>
                        </div>
                        </div>
                        
                    </div>

                    <div class="main-game">
                        <div class="main-game__col">
                        <span class="main-game__value">{Number(amount * 2 ).toFixed(2)}</span>
                        <span class="main-game__text">{t("You win")} (TON)</span>
                        </div>
                        <div className={`main-game__image ${result == -1 ? 'main-game__image--norate' : ''} ${result == 1 ? 'win' : ''} ${result == 0 ? 'lost' : ''}` }>
                        <picture>
                            {
                                resultImg == 1 ? <><source type='image/webp' srcset='img/index/coin2.webp'/>
                                <img width='137' height='137' src='img/index/coin2.png' alt='image'/>
                            </> : <>
                                <source type='image/webp' srcset='img/index/coin.webp'/>
                                <img width='137' height='137' src='img/index/coin.png' alt='image'/>
                            </>
                            }
                            
                        </picture>
                            <span class="main-game__rate">?</span>
                        </div>
                        <div class="main-game__col right">
                        <span class="main-game__value">x2</span>
                        <span class="main-game__text">{("Multiplayer")}</span>
                        </div>
                        <div class="main-game__bg"></div>
                        <span class="main-game__bet">{resultText}</span>
                    </div>

                    <div class="game-section__wrapp">
                    <div class="game-section__btns">
                    <button
                        type="button"
                        onClick={() => setBet(1)}
                        className={`main-btn main-btn--mode ${bet === 1 ? 'active' : ''}`}
                        >
                        <img width='33' height='33' src='img/index/coin2.png' alt='image'/>
                        {t("Eagle")}</button>
                        <button
                        type="button"
                        onClick={() => setBet(2)}
                        className={`main-btn main-btn--mode right ${bet === 2 ? 'active' : ''}`}
                        >
                        {t("Tails")}
                        <img width='33' height='33' src='img/index/coin3.png' alt='image'/>
                        </button>
                    </div>

                    <form class="main-form withdraw">
                        <div class="main-form__inner">
                        <span class="main-form__balance">{t("Amount")}:</span>

                        <div class="range">
                            <input type="range" step="0.001" min="0" max={balance} value={amount} onChange={(e) => setAmount(e.target.value)}/>
                            <div class="range__value">
                            <input type="text" value={amount} onChange={(e) => {
                                const value = e.target.value;

                                if (!isNaN(value) || value === '') {
                                setAmount(value);
                                }
                            }} />
                            <span class="range__icon">
                                <img width="24" height="24" src="./img/index/ton.png" alt="icon"/>
                            </span>
                            </div>
                        </div>
                        </div>

                        <button type="button" onClick={() => {playGame()}} class="blue-btn">{t("Play Game")}</button>
                    </form>
                    </div>

                    <ul class="history-list history-list--game">
                        <li class="history-list__row history-list__row--head">
                        <span class="history-list__name">{t("Date")}</span>
                        <span class="history-list__name">{t("Bet")}</span>
                        <span class="history-list__name">{t("Payout")}</span>
                        </li>
                        {gamesHistory && gamesHistory.map((item) => (
                                                   <>
                                                   <li class="history-list__row">
                                <span class="history-list__value">{formatTimestampToDate(item.date)}</span>
                                <span class="history-list__value">
                                    <img width='20' height='20' src='img/index/ton.png' alt='image'/>
                                    <i>{Number(item.amount).toFixed(2)} TON</i>
                                </span>
                                <span class="history-list__value">
                                    {item.result == 1 ?<><img width='20' height='20' src='img/index/ton.png' alt='image'/>
                                    <i>{Number(item.amount * 2).toFixed(2)} TON</i></> : <>-</>}
                                    <i>-</i>
                                </span>
                                </li>
                          </>
                            ))}
                        
                    </ul>
                    </div>
                </div>
                </section>
            </main>


        </>
    );
};

export default Game;
